<template>
    <modal ref="modalFinalizarPedidos" titulo="Finalizar pedidos" tamano="modal-xl" no-aceptar adicional="Finalizar" @adicional="finalizarMasivo">
        <div class="row mx-0 pt-5">
            <p class="col-auto f-17 f-500 text-general mb-4">
                Pedidos del {{ fecha }}
            </p>
            <div class="col-3 mb-4 ml-auto">
                <el-input v-model="buscar" placeholder="Buscar" class="br-20 w-100" size="small" />
            </div>
            <div class="col-12">
                <el-table
                :data="pedidos"
                header-row-class-name="text-general f-16 f-500"
                style="width: 100%"
                >
                    <el-table-column
                    fixed
                    class-name="text-center"
                    width="160"
                    >
                        <template slot-scope="scope">
                            <div class="row mx-0 justify-content-around">
                                <div class="col px-0">
                                    <el-tooltip v-if="scope.row.total_entregas > 0" placement="bottom" content="Nuevo intento de entrega" effect="light">
                                        <i class="icon-truck f-20 text-green mx-2" />
                                    </el-tooltip>
                                </div>
                                <div class="col px-0">
                                    <el-tooltip v-if="scope.row.nuevo_intento > 1" placement="bottom" content="Nuevo intento de entrega" effect="light">
                                        <i class="icon-history f-20 text-general mx-2" />
                                    </el-tooltip>
                                </div>
                                <div class="col px-0">
                                    <el-tooltip v-if="scope.row.productos_faltantes" placement="bottom" content="Productos faltantes" effect="light">
                                        <i class="icon-package-variant f-20 text-general-red mx-2" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column fixed prop="id" label="Pedido" min-width="100" />
                    <el-table-column label="Vendedor" prop="leechero" min-width="150" class-name="text-center" />
                    <el-table-column prop="cliente" label="Cliente" class-name="text-center" min-width="150" />
                    <el-table-column label="Valor" min-width="120" class-name="text-center">
                        <template slot-scope="scope">
                            <p>
                                {{ separadorNumero(scope.row.valor_final_pedido) }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Fecha de entrega" min-width="230" class-name="text-center" >
                        <template slot-scope="scope">
                            <div class="row mx-0">
                                <div class="col-12 px-0">
                                    <el-date-picker
                                    v-model="scope.row.entrega_fecha"
                                    size="small"
                                    type="date"
                                    format="yyyy-MM-dd"
                                    value-format="yyyy-MM-dd"
                                    :clearable="false"
                                    />
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Hora de entrega"
                    class-name="text-center"
                    min-width="250"
                    >
                        <template slot-scope="{row}">
                            <div class="row mx-0">
                                <div class="col-12 px-0">
                                    <el-select v-model="row.entrega_horario" size="small" placeholder="Horario" class="w-100">
                                        <el-option
                                        v-for="item in getHorarios(row.id_cedis)"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                        />
                                    </el-select>
                                </div>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="total_peso" label="Peso (Kg)" class-name="text-center" min-width="150" />
                    <el-table-column prop="total_volumen" label="Volumen (m3)" class-name="text-center" min-width="150" />
                    <el-table-column label="Valor" min-width="120" class-name="text-center">
                        <template slot-scope="scope">
                            <p>
                                {{ separadorNumero(scope.row.valor_condicion) }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Cobro de la comisión"
                    class-name="text-center"
                    width="200"
                    >
                        <template slot-scope="{row}">
                            <div class="row mx-0">
                                <div class="col-12 px-0">
                                    <el-select v-model="row.alianza_estado" size="small" placeholder="comisión">
                                        <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                        />
                                    </el-select>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Enviar a:"
                    class-name="text-center"
                    width="130"
                    >
                        <template slot-scope="{row}">
                            <div class="row mx-0">
                                <div class="col-12 px-0">
                                    <el-select v-model="row.cliente_entrega_directa" placeholder="Select" size="small" class="w-100">
                                        <el-option label="Vendedor" :value="0" />
                                        <el-option label="Cliente" :value="1" />
                                    </el-select>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Diferente dirección"
                    class-name="text-center"
                    width="180"
                    >
                        <template slot-scope="{row}">
                            <div class="row mx-0">
                                <div class="col-12 px-0">
                                    <el-checkbox v-model="row.otra_direccion" :true-label="1" :false-label="0" class="check-dark" />
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </modal>
</template>

<script>
import Pedido from "~/services/pedidos/pedidos-routing";
import moment from 'moment'
export default {
    data(){
        return {
            buscar: '',
            options: [{
                value: 1,
                label: 'No Aplica'
            }, {
                value: 2,
                label: 'Cobrada'
            }, {
                value: 3,
                label: 'Por Pagar'
            }, {
                value: 4,
                label: 'Pendiente Definir'
            }],
            value: '',
            tableData: [{
                productos_faltantes: true,
                nuevo_intento: true,
                id_pedido: 352415,
                leechero: 'Pan torres',
                cliente: 'Carlos Aguilar',
                comision_leechero: '$ 587.000',
                productos: 5,
                fecha_entrega: '',
                hora_entrega: '',
                direccion: false,
                valor: '$ 557.800',
                vehiculo: 'FRD 451',
                conductor: 'Pedro Ardila',
                estado_leeche: 'Confirmado',
                estado_logistica: 'Estado 1',
                peso: 100 ,
                volumen: 215
            }],
            horariosCedis:[],
            pedidos:[],
            fecha:''
        }
    },
    methods: {
        async toggle(pedidos){
            await this.loadHorarios()
            this.pedidos = pedidos.filter(p => p.estado === 3)
            if(this.pedidos.length){
                const f = this.pedidos[0].entrega_fecha + " " + this.pedidos[0].entrega_horario + ":00"
                const fecha = moment(f).format('DD [de] MMMM [de] YYYY [desde las] hh:mm a')
                this.fecha = fecha
            }else{
                this.notificacion('Alerta','Solo se pueden finalizar pedidos en estado enviado','warning')
                return
            }
            this.$refs.modalFinalizarPedidos.toggle();
        },
        async finalizarMasivo(){

            let pedidos = []

            for (const p of this.pedidos){
                pedidos.push({
                    id_pedido: p.id,
                    alianza_estado: p.alianza_estado,
                    cliente_entrega_directa: p.cliente_entrega_directa,
                    entrega_fecha: p.entrega_fecha,
                    entrega_horario: p.entrega_horario,
                    otra_direccion: p.otra_direccion,
                    finalizar:true
                })
            }
            const form = {pedidos}

            const {data} = await Pedido.finalizarMasivo(form)
            this.$emit('update')
            this.notificacion('Exito','Pedidos finalizados correctamente','success')
            this.$refs.modalFinalizarPedidos.toggle();

        },
        async loadHorarios(){
            try {
                const {data} = await Pedido.getHorariosCedis()
                this.horariosCedis = data.cedis_horarios
            } catch (error){
                this.error_catch(error)
            }
        },
        getHorarios(idCedis){
            let cedis = this.horariosCedis.find(c=>c.id_cedis === idCedis)
            return cedis.horarios
        }
    }
}
</script>
<style lang="css" scoped>
.text-green{
    color: #02A251;
}
</style>
